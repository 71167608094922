/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useEventListener, useFunctionState } from 'vspry-hooks'
import { Button, FlexBox, Icon, Text, TextInput, Tile, ErrorMessage } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { useRouting } from 'context/routingContext'

const init = {
    email: '',
    password: '',
    hidePassword: true,
    submitting: false,
    error: '',
}

export default function EmailSignIn() {
    const { navigateBack, navigateTo } = useRouting()
    const { signin, error: authError } = useAuth()
    const [{ email, password, hidePassword, submitting, error }, setState] = useFunctionState(init)

    useEffect(() => authError && setState({ error: 'Incorrect email or password' }), [authError])

    const handleSubmit = async () => {
        if (!email) return setState({ error: 'You have not entered an email' })
        if (!password) return setState({ error: 'You have not entered a password' })
        setState({ submitting: true, error: '' })
        try {
            await signin(email, password)
            navigateTo('/home')
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message)
            setState({ error: 'Incorrect email or password' })
        }
        return setState({ submitting: false })
    }

    useEventListener('keypress', (event) => event.keyCode === 13 && handleSubmit())

    return (
        <FlexBox $column width='350px' gap='small'>
            {error && <ErrorMessage error={error} />}
            <Tile>
                <FlexBox $column $align gap='small'>
                    <TextInput label='Email' value={email} handleChange={(v) => setState({ email: v })} />
                    <TextInput
                        label='Password'
                        type={hidePassword ? 'password' : 'input'}
                        value={password}
                        handleChange={(v) => setState({ password: v })}
                        suffix={<Icon onClick={() => setState({ hidePassword: !hidePassword })} name={hidePassword ? 'eye' : 'eye slash'} />}
                        guide={
                            <Text size='xSmall' margin='no' onClick={() => navigateTo('/resetpassword')}>
                                Forgot your password?
                            </Text>
                        }
                    />
                </FlexBox>
            </Tile>
            <Button color='textLight' $fitted onClick={handleSubmit} loading={submitting}>
                Sign in
            </Button>
            <Button color='brand1' $fitted onClick={() => navigateBack()}>
                Back
            </Button>
        </FlexBox>
    )
}
