/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
export default {
    internalName: 'philhealth',
    name: 'Philhealth',
    payor: 'PHILAM',
    banner: 'philhealth.png',
    companyName: 'Philippine Health Insurance Corporation',
    theme: {
        text: '#464646',
        textLight: '#FFFFFF',
        success: '#00a99d',
        error: '#c23e27',
        warn: '#e39f00',
        background: '#F6F6F6',
        brand1: '#329900',
        buttonBG: '#329900',
        font: `'Lato', sans-serif`,
        fontURL: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
    },
    cardBackground: 'philhealth-card.png',
}
