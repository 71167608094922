/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import PropTypes from 'prop-types'
import { VspryCard } from 'vspry-components'
import { FlexBox, Text } from 'vspry-style-components'

import { formatGovCardNumber, formatNameToMaxLength } from 'utils/strings'
import { formatDateLocale } from 'utils/dates'
import { useBrand } from 'context/brandContext'
import { getImage } from './LogoImage'

export default function InsuranceCard({ card, onClick }) {
    const brand = useBrand()

    return (
        <VspryCard $sheen onClick={onClick} brand={{ color1: brand.theme.brand1, cardBackground: getImage(brand.cardBackground) }}>
            <FlexBox $column gap='small' justify='end' height='100%' padding='medium'>
                <FlexBox gap='small'>
                    <div style={{ width: '110px' }} />
                    <FlexBox $column gap='xSmall'>
                        <Text id='government-health-cardnumber' size='small' margin='no' $left $light $monospace $embossed>
                            {formatGovCardNumber(card.cardNumber)}
                        </Text>
                        <div>
                            <Text id='government-health-name' $left $light size='tiny' margin='tiny' $embossed>
                                {card.person.fullName?.toUpperCase() ?? formatNameToMaxLength(card.person, 25).toUpperCase()}
                            </Text>
                            <Text id='government-health-birthDate-gender' $left $light size='tiny' margin='tiny' $embossed>
                                {formatDateLocale(card.person.birthDate)}, MALE
                            </Text>
                            <Text id='government-health-address' $left $light size='tiny' margin='tiny' $embossed>
                                MAKATI CITY, NCR 1201
                            </Text>
                        </div>
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </VspryCard>
    )
}

InsuranceCard.propTypes = {
    card: PropTypes.object.isRequired,
    onClick: PropTypes.func,
}

InsuranceCard.defaultProps = {
    onClick: null,
}
