/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'

import { useRouting } from 'context/routingContext'
import modules from 'images/modules'

import { FlexBox, Text } from 'vspry-style-components'

const Image = styled.img`
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
`

export const getImage = (image) => {
    const moduleItem = modules.find((m) => m.name === `./${image}`)
    if (!moduleItem) return null
    return moduleItem.module
}

const sizes = ['small', 'medium', 'large']

export default function LogoImage({ image, text, size }) {
    const { navigateHome } = useRouting()
    const imageFile = getImage(image)
    return (
        <FlexBox height={`${sizes.findIndex((s) => s === size) * 20 + 40}px`} align='center'>
            {image && imageFile ? (
                <Image src={imageFile} alt='banner' onClick={() => navigateHome()} />
            ) : (
                <Text size='xLarge' margin='xBottom' $light $left $bold>
                    {text}
                </Text>
            )}
        </FlexBox>
    )
}

LogoImage.propTypes = {
    image: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.string,
}

LogoImage.defaultProps = {
    size: 'small',
}
