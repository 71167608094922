/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

// packages
import React from 'react'
import { Button, Text, TextInput, FlexBox, Tile } from 'vspry-style-components'
import { useEventListener, useFunctionState } from 'vspry-hooks'

import { useRouting } from 'context/routingContext'

// components
import { PublicPageContainer } from 'components/page'

const initState = {
    email: '',
    working: false,
    sent: false,
    error: '',
}

export default function ResetPasswordPage() {
    const { navigateTo } = useRouting()
    const [state, setState] = useFunctionState(initState)

    const { email, working, sent, error } = state

    /**
     * Requests the reset email for the user's password
     */
    const requestReset = async () => {
        setState({ working: true })
        // attempt to send an email using the provided email
        try {
            await window.auth.sendPasswordResetEmail(email)
        } catch (e) {
            if (e.code.includes('user-not-found')) return setState({ working: false, sent: true })
            // eslint-disable-next-line no-console
            console.error(e)
            return setState({
                working: false,
                error: e.code.includes('invalid-email') || e.code.includes('missing-email') ? 'Invalid email' : 'Unexpected error',
            })
        }

        // change state to sent
        return setState({ working: false, sent: true })
    }

    useEventListener('keypress', (event) => event.keyCode === 13 && requestReset())

    /**
     * renders the password reset form
     */
    const renderRequestForm = () => (
        <FlexBox $column gap='small'>
            <Text size='small' color='textLight'>
                Enter the email address associated with your account below, and we will send you an email with instructions to reset your password.
            </Text>
            <Tile>
                <FlexBox $column gap='small'>
                    <TextInput
                        id='email-field'
                        label='Email'
                        value={email}
                        handleChange={(input) => setState({ email: input })}
                        guide={
                            error ? (
                                <Text size='small' color='error' margin='no'>
                                    {error}
                                </Text>
                            ) : null
                        }
                    />
                </FlexBox>
            </Tile>
            <Button id='request-reset' color='textLight' $fitted onClick={requestReset} loading={working} disabled={!email}>
                Request reset email
            </Button>
        </FlexBox>
    )

    /**
     * renders the resulting password sent form
     */
    const renderSentForm = () => (
        <>
            <Text size='small' color='textLight'>
                If the email you entered matches a valid account, you will have received an email from us with instructions on how to reset your
                password. Be sure to check your junk mail folder as well.
            </Text>
            <Button id='sign-in-click' color='textLight' $fitted onClick={() => navigateTo('/signin')}>
                Sign In
            </Button>
            <Button id='resend' $fitted onClick={requestReset} loading={working}>
                I did not receive an email
            </Button>
        </>
    )

    /**
     * renders the page
     */
    return (
        <PublicPageContainer>
            <FlexBox $column width='350px' gap='small'>
                {!sent && renderRequestForm()}
                {sent && renderSentForm()}
                <Button $fitted onClick={() => navigateTo('/')}>
                    Cancel
                </Button>
            </FlexBox>
        </PublicPageContainer>
    )
}
