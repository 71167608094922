/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { graphFormMutation, graphMutation, graphQuery } from './interface'
import { getMessagingToken } from './firebase'

const wait = async (t) =>
    new Promise((r) => {
        setTimeout(r, t)
    })

export const getUserDetailsByCard = async (payor, cardNumber, birthDate) => {
    await wait(2000)

    return [
        {
            person: {
                fullName: 'John Vincent T. Dela Cruz',
            },
        },
        null,
    ]
    // const [user, userError] = await graphQuery`
    //     authenticateMember(input: ${{ payor, birthDate: new Date(birthDate).toISOString(), cardNumber }}) {
    //         authenticated
    //         member {
    //             fullName
    //         }
    //         errorCode
    //         errorMessage
    //     }
    // `
    // if (userError?.message === 'found existing contact for card number')
    //     return [
    //         null,
    //         {
    //             message:
    //                 'Account already exists. Please sign in with your email and password, or click the password reset link if you have forgotten your credentials.',
    //         },
    //     ]
    // if (userError) return [null, userError]
    // if (user?.authenticateMember?.errorMessage) return [null, { message: user?.authenticateMember?.errorMessage }]

    // return [
    //     {
    //         person: {
    //             fullName: user.authenticateMember.member.fullName,
    //         },
    //     },
    //     null,
    // ]
}

export const signUpUser = async (input) => graphMutation`
    signUpUser(input: ${input})
`

// returns user details using a token to authenticate
export const getUserDetails = async (payor) => {
    const input = { payor }
    const person = `person { firstName middleName lastName suffix birthDate fullName }`
    const [usr, usrErr] = await graphQuery`
        contact(input: ${input}) {
            email
            phone
            features
            sessionExpiry
            ${person}
        }`
    if (usrErr) return [null, usrErr]
    const [holdings, holdingsErr] = await graphQuery`
        contact(input: ${input}) {
            holdings {
                mediLinkPH { cardNumber ${person} }
                ${usr.contact.features.includes('PhilHealth') ? `philHealth { cardNumber email phone ${person} }` : ``}
                ${usr.contact.features.includes('VaxCertPH') ? `vaxCertPH { ${person} vaccinations { location date } }` : ``}
            }
        }
    `
    if (holdingsErr) return [null, holdingsErr]
    return [{ ...usr.contact, ...holdings.contact }, null]
}

// adds a user's device to the device notifications list
export const addUserDevice = async (notificationToken) => {
    const input = { id: notificationToken }
    return graphMutation`
        addUserDevice(input: ${input})
    `
}

// uploads a document for a user
export const uploadUserDocument = async (classification, files) => {
    const query = `mutation ($files: [Upload!]!) {
        uploadUserDocument(input: { classification: "${classification}", files: $files }) {
            classification
            files {
                url
                name
                size
            }
        }
    }`
    return graphFormMutation(query, { files })
}

// initiates notifications for a user
export const initNotifications = async () => {
    try {
        const notificationToken = await getMessagingToken()
        if (!notificationToken) return
        await addUserDevice(notificationToken)
    } catch (e) {
        // eslint-disable-next-line no-console
        if (!e.message.includes('permission-blocked') && !e.message.includes('unsupported-browser')) console.error(e)
    }
}

export const getUserMessages = async () => {
    const [res, err] = await graphQuery`
        messages {
            id
            message
            title
            sender
            attachments {
                name
                url
                expiry
            }
            expiry
            read
            date
        }
    `

    if (err) return null
    return res.messages
}

export const setMessageRead = (id) => {
    const input = { id }
    return graphMutation`
        readMessage(input: ${input}) {
            id
            message
            title
            sender
            attachments {
                name
                url
                expiry
            }
            expiry
            read
            date
        }
    `
}

export const getUserDocuments = async () => {
    const [res, err] = await graphQuery`
        documents {
            id
            files {
                url
                name
                size
            }
            classification
            date
        }
    `

    if (err) return null
    return res.documents
}

export const clearUserData = async () => {
    const [res, err] = await graphMutation`
        clearUser
    `

    if (err) return null
    return res
}
