/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FlexBox, Text, Counter, Icon } from 'vspry-style-components'

const Tile = styled(FlexBox)`
    width: 100%;
    padding: 10px;
    margin: 0px;
    border-radius: 5px;
    transition: background-color 400ms ease-out;

    :hover {
        background-color: ${(props) => props.theme.brand1}44;
        cursor: pointer;
    }
`

const StyledIcon = styled(Icon)`
    width: 27px;
`

export default function NavBarItem({ children, onClick, closeBar, icon, count, $reverse, extraLabel }) {
    const handleClick = () => {
        closeBar()
        return setTimeout(() => onClick(), 400)
    }

    if ($reverse)
        return (
            <Tile onClick={handleClick} direction='column'>
                <FlexBox justify='flex-end' gap='small' align='center'>
                    <Text $left margin='no' size='small'>
                        {children}
                    </Text>
                    {icon && <StyledIcon color='text' size='large' name={icon} />}
                </FlexBox>
                {extraLabel && (
                    <Text color='error' size='tiny' margin='no' $right>
                        {extraLabel}
                    </Text>
                )}
            </Tile>
        )

    return (
        <Tile onClick={handleClick} direction='column'>
            <FlexBox justify='flex-start' gap='small' align='center'>
                {icon && <StyledIcon color='text' size='large' name={icon} />}
                <Text $left margin='no' size='small'>
                    {children}
                </Text>
                {count > 0 && <Counter count={count} />}
            </FlexBox>
            {extraLabel && (
                <Text color='error' size='tiny' margin='no' $right>
                    {extraLabel}
                </Text>
            )}
        </Tile>
    )
}

NavBarItem.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    closeBar: PropTypes.func.isRequired,
    icon: PropTypes.string,
    count: PropTypes.number,
    $reverse: PropTypes.bool,
    extraLabel: PropTypes.string,
}

NavBarItem.defaultProps = {
    icon: null,
    count: null,
    $reverse: false,
    extraLabel: null,
}
