/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { Button, FlexBox } from 'vspry-style-components'

import { useRouting } from 'context/routingContext'

import { PublicPageContainer } from 'components/page'
import { useAuth } from 'context/authContext'

export default function LandingPage() {
    const { navigateTo } = useRouting()
    const { user } = useAuth()

    useEffect(() => user && navigateTo('/home'), [user])

    return (
        <PublicPageContainer>
            <FlexBox $column width='350px' gap='small'>
                <Button color='textLight' $fitted onClick={() => navigateTo('/signin?type=email')}>
                    Sign in
                </Button>
                <Button $fitted onClick={() => navigateTo('/signin?type=card')}>
                    Create new account
                </Button>
            </FlexBox>
        </PublicPageContainer>
    )
}
